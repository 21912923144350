<template>
    <v-container fluid>
        <v-card elevation="6" outlined>
            <v-form lazy-validation ref="form">
                <v-row align="center" class="pa-3" justify="center">
                    <v-col class="text-center" cols="12">
                        <div class="mx-auto pa-2">
                            <v-avatar color="primary" size="100">
                                <v-img :src="this.user.avatar"/>
                            </v-avatar>
                        </div>
                        <strong>
                            {{this.user.editUserPreName + " " + this.user.editUserLastName}}
                        </strong>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-datetime-picker
                                :datePickerProps="{
                                    max: this.getMaxDate('start')
                                }"
                                :label="$t('timetrack.beginWorkday')"
                                :textFieldProps="{outlined:true,dense:true, 'prepend-inner-icon' : 'event', rules: [v => !!v]}"
                                :timePickerProps="{
                                     format: '24hr'
                                }"
                                outlined
                                ref="started"
                                v-bind:datetime="this.form.startTimeStamp"
                                v-model="form.startTimeStamp"/>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <v-datetime-picker
                                :datePickerProps="{
                                    max: this.MaxDateEnd,
                                    min: this.MinDateEnd,
                                }"
                                :label="$t('timetrack.endWorkday')"
                                :textFieldProps="{outlined:true,dense:true, 'prepend-inner-icon' : 'event', rules: [v => !!v]}"
                                :timePickerProps="{
                                     format: '24hr'
                                }"
                                outlined
                                v-model="form.endTimeStamp"/>
                    </v-col>

                    <v-col cols="12" md="4" sm="6">
                        <BaseTimeInput :label="$t('timetrack.pauseTime')" :rules="[ v => !!v]" dense
                                      outlined v-model="form.pauseTime"/>
                    </v-col>

                    <v-col cols="12">
                        <v-select :items="this.tags" :label="$t('timetrack.typeOfDay')" :rules="[ v => !!v]" dense
                                  item-text="name"
                                  item-value="id" outlined v-model="form.entryType"/>
                    </v-col>

                    <v-col cols="12">
                        <v-textarea :label="$t('timetrack.ACCOMPLISHEDWORKS')" outlined v-model="form.notes"/>
                    </v-col>

                    <v-col class="text-right" cols="12">
                        <v-btn color="error" link to="/timetrack/timeCardDaily">
                            {{this.$t('generic.lang_cancel')}}
                        </v-btn>

                        <v-btn :disabled="this.loading" :loading="this.loading" @click="create()" color="primary">
                            {{this.$t('generic.lang_create')}}
                        </v-btn>

                        <v-btn :disabled="this.loading" :loading="this.loading" @click="create(true)" color="success">
                            {{this.$t('generic.lang_next')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
    </v-container>
</template>

<script>
    import Birthdaypicker from "../../common/birthdaypicker";
    import {ENDPOINTS} from "../../../config";
    import {Events} from "../../../plugins/events";
    import BaseTimeInput from "@/components/common/BaseTimeInput.vue";

    export default {
        name: "TrackComponent",
        components: {Birthdaypicker, BaseTimeInput},
        data() {
            return {
                isEnd: false,
                MaxDateEnd: "",
                MinDateEnd: "",
                loading: false,
                user: {
                    avatar: ""
                },
                form: {
                    startTimeStamp: null,
                    endTimeStamp: null
                },
                tags: [
                    {
                        id: 1,
                        name: this.$t('timetrack.workday')
                    },
                    {
                        id: 2,
                        name: this.$t("timetrack.holiday")
                    },
                    {
                        id: 3,
                        name: this.$t("timetrack.sickday")
                    },
                    {
                        id: 4,
                        name: this.$t("timetrack.feastday")
                    },
                    {
                        id: 5,
                        name: this.$t("timetrack.weekend")
                    }
                ]
            }
        },
      mounted() {
            this.form.startTimeStamp = new Date();
            this.loadData(this.$route.params.id);
        },
        computed: {
            currentDate() {
                let dt = new Date();
                return this.dateFormater(dt) + 'T' + this.timeFormater(dt);
            },

        },
        watch: {
            'form.startTimeStamp': function (val) {

                if (this.form.startTimeStamp !== null && !this.isEnd) {
                    let dt = new Date(val);
                    dt.setDate(val.getDate() + 1);
                    let time = val.getTime();

                    if (this.form.endTimeStamp !== null) {
                        if (time >= this.form.endTimeStamp.getTime()) {
                            val.setDate(this.form.endTimeStamp.getDate())
                            val.setHours(this.form.endTimeStamp.getHours() - 1)
                            val.setMinutes(this.form.endTimeStamp.getMinutes())

                            val && this.$swal({
                                title: this.$t('timetrack.lang_pleaseSelectDateLessThanEndedDate'),
                                text: this.$t('timetrack.lang_wereSetYourStartedDateToEndedDateMinusOneHour'),
                                icon: "warning",
                                buttons:["OK"]
                            })

                        }
                    } else
                        val & (this.MaxDateEnd = this.dateFormater(dt)) & (this.MinDateEnd = this.dateFormater(val));
                }
            },
            'form.endTimeStamp': function (val) {
                this.isEnd = true;
                let st = new Date(this.form.startTimeStamp)
                if (val.getTime() <= st.getTime()) {

                    val.setDate(st.getDate())
                    val.setHours(st.getHours() + 1)
                    val.setMinutes(st.getMinutes())

                    val && this.$swal({
                        title: this.$t('timetrack.lang_pleaseSelectDateUpToStartedDate'),
                        text: this.$t('timetrack.lang_wereSetYourEndedDateToStartedDatePlusOneHour'),
                        icon: "warning",
                        buttons:["OK"]
                    }) && (this.isEnd = false);
                } else
                    val && (this.isEnd = false);
            }
        },
        methods: {
            getMaxDate(type) // this function to get max of ending time
            {
                if (type === "start")
                    return this.currentDate.split('T')[0];
            },
            dateFormater(date) {
                let day = parseInt(date.getDate()) < 10 ? '0' + date.getDate() : date.getDate(),
                    month = (parseInt(date.getMonth()) + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1),
                    year = parseInt(date.getFullYear());
                return year + '-' + month + '-' + day
            },
            timeFormater(date) {
                let hours = parseInt(date.getHours()) < 10 ? '0' + date.getHours() : date.getHours(),
                    minutes = parseInt(date.getMinutes()) < 10 ? '0' + date.getMinutes() : date.getMinutes();
                return hours + ':' + minutes
            },
            next() {
                this.axios.post(ENDPOINTS.TIMESHEET.DAILYTIMECARD.NEXT, {
                    currentUserID: this.$route.params.id
                }).then((res) => {
                    //console.log(res);

                    if (res.data.STATUS === 'SUCCESS') {

                        if (res.data.userID == this.$route.params.id || this.$route.params.id == 0)
                            this.$router.push('/timetrack/timeCardDaily');
                        else {
                            this.$router.push('/timetrack/timeCardDaily/track/' + res.data.userID);
                            this.loadData(res.data.userID);
                            this.$refs.form.reset();
                        }
                    } else {
                        this.$router.push('/timetrack/timeCardDaily');
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                })
            },
            create(next = false) {
                if (!this.$refs.form.validate()) return;
                this.loading = true;

                this.form.pauseTime = parseInt(this.form.pauseTime.split(':')[0]) * 3600 + parseInt(this.form.pauseTime.split(':')[1]) * 60;
                this.form.startTimeStamp = Math.round(+new Date(this.form.startTimeStamp).getTime() / 1000);
                this.form.endTimeStamp = Math.round(+new Date(this.form.endTimeStamp).getTime() / 1000);
                this.form.userID = this.$route.params.id;
                this.axios.post(ENDPOINTS.TIMESHEET.DAILYTIMECARD.CREATE, this.form).then((res) => {
                    if (res.data.STATUS === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_createdSuccessfully'),
                            color: "success"
                        });

                        if (next)
                            this.next();
                        else this.$router.push('/timetrack/timeCardDaily');
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                })

            },
            loadData(currentUserID) {
                this.axios.post(ENDPOINTS.SETTINGS.USER.GET, {
                    userID: currentUserID
                }).then((res) => {
                    this.user = res.data.userData;
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: err.message,
                        color: "error"
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
