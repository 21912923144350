var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"6","outlined":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"mx-auto pa-2"},[_c('v-avatar',{attrs:{"color":"primary","size":"100"}},[_c('v-img',{attrs:{"src":this.user.avatar}})],1)],1),_c('strong',[_vm._v(" "+_vm._s(this.user.editUserPreName + " " + this.user.editUserLastName)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-datetime-picker',{ref:"started",attrs:{"datePickerProps":{
                                max: this.getMaxDate('start')
                            },"label":_vm.$t('timetrack.beginWorkday'),"textFieldProps":{outlined:true,dense:true, 'prepend-inner-icon' : 'event', rules: [v => !!v]},"timePickerProps":{
                                 format: '24hr'
                            },"outlined":"","datetime":this.form.startTimeStamp},model:{value:(_vm.form.startTimeStamp),callback:function ($$v) {_vm.$set(_vm.form, "startTimeStamp", $$v)},expression:"form.startTimeStamp"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-datetime-picker',{attrs:{"datePickerProps":{
                                max: this.MaxDateEnd,
                                min: this.MinDateEnd,
                            },"label":_vm.$t('timetrack.endWorkday'),"textFieldProps":{outlined:true,dense:true, 'prepend-inner-icon' : 'event', rules: [v => !!v]},"timePickerProps":{
                                 format: '24hr'
                            },"outlined":""},model:{value:(_vm.form.endTimeStamp),callback:function ($$v) {_vm.$set(_vm.form, "endTimeStamp", $$v)},expression:"form.endTimeStamp"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('BaseTimeInput',{attrs:{"label":_vm.$t('timetrack.pauseTime'),"rules":[ v => !!v],"dense":"","outlined":""},model:{value:(_vm.form.pauseTime),callback:function ($$v) {_vm.$set(_vm.form, "pauseTime", $$v)},expression:"form.pauseTime"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":this.tags,"label":_vm.$t('timetrack.typeOfDay'),"rules":[ v => !!v],"dense":"","item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.form.entryType),callback:function ($$v) {_vm.$set(_vm.form, "entryType", $$v)},expression:"form.entryType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('timetrack.ACCOMPLISHEDWORKS'),"outlined":""},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"error","link":"","to":"/timetrack/timeCardDaily"}},[_vm._v(" "+_vm._s(this.$t('generic.lang_cancel'))+" ")]),_c('v-btn',{attrs:{"disabled":this.loading,"loading":this.loading,"color":"primary"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(this.$t('generic.lang_create'))+" ")]),_c('v-btn',{attrs:{"disabled":this.loading,"loading":this.loading,"color":"success"},on:{"click":function($event){return _vm.create(true)}}},[_vm._v(" "+_vm._s(this.$t('generic.lang_next'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }